import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tabs, Tab } from "../components/UI/Tabs";
import Card from "../components/UI/Card";
import budget from "../assets/illustrations/budget.svg";
import Table from "../components/UI/Table";
import ListSkeleton from "../components/UI/skeleton/ListSkeleton";
import { gql, useQuery } from "@apollo/client";
import { Warning } from "@mui/icons-material";
import Toast from "../components/UI/Toast";
import { useProject } from "../store/project-context";
import NoProject from "../components/UI/NoProject";
import ApexChart from "react-apexcharts";

const GET_REPORTS = gql`
  query MyQuery($projectId: uuid!) {
    projects_report(
      where: { projectId: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      equipments
      id
      labor
      materials
      milestoneId
    }
  }
`;
const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { created_at: desc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      milestone_equipments {
        id
        quantity
        unitPrice
        equipment {
          id
          name
          unit
        }
      }
      milestone_materials {
        id
        quantity
        unitPrice
        material {
          id
          name
          unit
        }
      }
      milestone_labors {
        id
        quantity
        unitPrice
        labor {
          id
          type
          # payPerWorker
        }
      }
    }
  }
`;

const BudgetPage = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [cardVisible, setCardVisible] = useState(false);
  const [chart1, setChart1] = useState({
    materials: 0,
    labors: 0,
    equipments: 0,
  });
  const [chart2, setChart2] = useState({
    materials: 0,
    labors: 0,
    equipments: 0,
  });
  const { currentProject } = useProject();
  const {
    data: reports,
    loading: reportsLoading,
    error: reportsError,
  } = useQuery(GET_REPORTS, {
    variables: { projectId: currentProject?.id },
    fetchPolicy: "no-cache",
  });
  const {
    data: milestones,
    loading: milestonesLoading,
    error: milestonesError,
  } = useQuery(GET_MILESTONES, {
    variables: { projectId: currentProject?.id },
  });

  const cardRef = useRef();
  const tabRef = useRef();
  useEffect(() => {
    async function fetchData() {
      setSelectedItem(null);
      if (currentProject?.id && !reportsLoading) {
        let materials = [];
        let equipments = [];
        let labors = [];
        reports?.projects_report?.map((report) => {
          console.log(report);
          if (JSON.parse(report.materials))
            materials.push(...JSON.parse(report.materials));
          if (JSON.parse(report.equipments))
            equipments.push(...JSON.parse(report.equipments));
          if (JSON.parse(report.labor))
            labors.push(...JSON.parse(report.labor));
        });
        console.log(materials);
        let final_materials = [];
        let final_equipments = [];
        let final_labors = [];
        // categorize
        materials.map((material) => {
          let found = false;
          final_materials.map((mat) => {
            if (material.id === mat.id) {
              mat.count++;
              mat.quantity =
                parseInt(mat.quantity) + parseInt(material.quantity);
              // mat.unitPrice =
              //   parseFloat(mat.unitPrice) + parseFloat(material.unitPrice);
              mat.totalCost += material.totalPrice;
              found = true;
              // console.log(material.unitPrice, material.quantity);
            }
          });
          if (!found) {
            if (material)
              final_materials.push({
                ...material,
                count: 1,
                itemType: "Material",
                totalCost: material.totalPrice,
              });
            // console.log(material.unitPrice, material.quantity);
          }
        });
        final_materials.map((material) => {
          // material.averagePrice = material.unitPrice / material.count;
          if (material.quantity === 0) {
            material.averagePrice = 0;
          } else {
            material.averagePrice = material.totalCost / material.quantity;
          }
        });
        equipments.map((equipment) => {
          let found = false;
          final_equipments.map((eq) => {
            if (equipment?.id === eq?.id) {
              eq.count++;
              eq.quantity =
                parseInt(eq.quantity) + parseInt(equipment.quantity);
              eq.unitPrice =
                parseFloat(eq.unitPrice) + parseFloat(equipment.unitPrice);
              eq.totalCost += equipment.unitPrice * equipment.quantity;
              found = true;
            }
          });
          if (!found) {
            if (equipment)
              final_equipments.push({
                ...equipment,
                count: 1,
                itemType: "Equipment",
                totalCost: equipment.unitPrice * equipment.quantity,
              });
          }
        });
        final_equipments.map((equipment) => {
          // equipment.averagePrice = equipment.unitPrice / equipment.count;
          if (equipment.quantity === 0) {
            equipment.averagePrice = 0;
          } else {
            equipment.averagePrice = equipment.totalCost / equipment.quantity;
          }
        });
        labors.map((labor) => {
          let found = false;
          final_labors.map((lab) => {
            console.log(lab);
            console.log(labor);
            if (labor?.id === lab?.id) {
              lab.count++;
              lab.quantity = parseInt(lab.quantity) + parseInt(labor.quantity);
              lab.payPerWorker =
                parseFloat(lab.payPerWorker) + parseFloat(labor.payPerWorker);
              lab.totalCost += labor.payPerWorker * labor.quantity;
              found = true;
            }
          });
          if (!found) {
            if (labor)
              final_labors.push({
                ...labor,
                count: 1,
                itemType: "Labor",
                totalCost: labor.payPerWorker * labor.quantity,
              });
          }
        });
        final_labors.map((labor) => {
          // labor.averagePrice = labor.payPerWorker / labor.count;
          if (labor.quantity === 0) {
            labor.averagePrice = 0;
          } else {
            labor.averagePrice = labor.totalCost / labor.quantity;
          }
        });
        let tbl_data = [
          ...final_materials,
          ...final_equipments,
          ...final_labors,
        ];

        if (currentProject?.id && !milestonesLoading) {
          tbl_data.map((data) => {
            console.log(data);
            let totalQuantity = 0;
            let totalCost = 0;
            milestones?.projects_milestones?.map((milestone) => {
              console.log(milestone);
              if (milestone.milestone_materials.length > 0) {
                milestone.milestone_materials.map((milestone_material) => {
                  if (milestone_material.material.id === data.id) {
                    totalQuantity += milestone_material.quantity;
                    totalCost +=
                      milestone_material.quantity *
                      milestone_material.unitPrice;
                    // data.remainingQuantity =
                    //   - data.quantity;
                    // data.costRemaining =
                    //   milestone_material.material.unitPrice *
                    //   data.remainingQuantity;
                  }
                });
              }
              if (milestone.milestone_equipments.length > 0) {
                milestone.milestone_equipments.map((milestone_equipment) => {
                  console.log(milestone_equipment.id, data.id);
                  if (milestone_equipment.equipment.id === data.id) {
                    totalQuantity += milestone_equipment.quantity;
                    totalCost +=
                      milestone_equipment.quantity *
                      milestone_equipment.unitPrice;
                    // data.remainingQuantity =
                    //   milestone_equipment.quantity - data.quantity;
                    // data.costRemaining =
                    //   milestone_equipment.equipment.unitPrice *
                    //   data.remainingQuantity;
                  }
                });
              }
              if (milestone.milestone_labors.length > 0) {
                milestone.milestone_labors.map((milestone_labor) => {
                  if (milestone_labor.labor.id === data.id) {
                    totalQuantity += milestone_labor.quantity;
                    totalCost +=
                      milestone_labor.quantity * milestone_labor.unitPrice;
                    // data.remainingQuantity =
                    //   milestone_labor.quantity - data.quantity;
                    // data.costRemaining =
                    //   milestone_labor.labor.payPerWorker *
                    //   data.remainingQuantity;
                  }
                });
              }
            });
            console.log(data.itemType, totalCost);
            data.remainingQuantity = totalQuantity - data.quantity;
            data.costRemaining = totalCost - data.totalCost;
          });
        }
        console.log(tbl_data);
        setTableData(
          tbl_data.map((data) => {
            if (data.itemType === "Labor") {
              return {
                id: data.id,
                itemName: data.type,
                itemType: data.itemType,
                averagePrice: data.averagePrice.toLocaleString(),
                quantity: data.quantity,
                totalCost: data.totalCost.toLocaleString(),
                remainingQuantity: data.remainingQuantity,
                costRemaining: data.costRemaining
                  ? data.costRemaining.toLocaleString()
                  : 0,
              };
            } else {
              return {
                id: data.id,
                itemName: data.name,
                itemType: data.itemType,
                averagePrice: data.averagePrice.toLocaleString(),
                quantity: data.quantity,
                totalCost: data.totalCost.toLocaleString(),
                remainingQuantity: data.remainingQuantity,
                costRemaining: data.costRemaining
                  ? data.costRemaining.toLocaleString()
                  : 0,
                unit: data.unit,
              };
            }
          })
        );
      }
    }
    fetchData();
  }, [currentProject?.id, reports?.projects_report]);
  useEffect(() => {
    async function fetchData() {
      if (currentProject?.id && !reportsLoading) {
        let materials = 0;
        let equipments = 0;
        let labors = 0;
        reports?.projects_report?.map((report) => {
          console.log(report);
          if (JSON.parse(report.materials)) {
            JSON.parse(report.materials).map((material) => {
              materials += material.totalPrice;
            });
          }
          if (JSON.parse(report.equipments)) {
            JSON.parse(report.equipments).map((equipment) => {
              equipments += equipment.quantity * equipment.unitPrice;
            });
          }
          if (JSON.parse(report.labor)) {
            JSON.parse(report.labor).map((labor) => {
              labors += labor.quantity * labor.payPerWorker;
            });
          }
        });
        setChart1({ materials, equipments, labors });
      }

      if (currentProject?.id && !milestonesLoading) {
        let materials = 0;
        let equipments = 0;
        let labors = 0;
        milestones?.projects_milestones?.map((milestone) => {
          console.log(milestone);
          if (milestone.milestone_materials) {
            milestone.milestone_materials.map((milestone_material) => {
              materials +=
                milestone_material.quantity * milestone_material.unitPrice;
            });
          }
          if (milestone.milestone_equipments) {
            milestone.milestone_equipments.map((milestone_equipment) => {
              equipments +=
                milestone_equipment.quantity * milestone_equipment.unitPrice;
            });
          }
          if (milestone.milestone_labors) {
            milestone.milestone_labors.map((milestone_labor) => {
              labors += milestone_labor.quantity * milestone_labor.unitPrice;
            });
          }
        });
        setChart2({ materials, equipments, labors });
      }
    }

    fetchData();
  }, [
    currentProject?.id,
    reports?.projects_report,
    reportsLoading,
    milestonesLoading,
  ]);

  const selectItem = (id) => {
    if (id === null) {
      setSelectedItem(null);
      return;
    }
    tableData.map((item) => {
      if (item.id === id) {
        setSelectedItem(item);
        return item;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
    // tabRef.current && tabRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const chartOptions = {
    chart: {
      type: "pie",
      foreColor: "#ffffff",
    },
    colors: ["#6050DC", "#FF6B45", "#FFAB05"],
    plotOptions: {
      pie: {
        expandOnClick: true,
        pie: {
          labels: {
            show: false,
            dataLabels: {
              enabled: true, // Set this option to true
              style: {
                fontSize: "16px", // Adjust font size as needed
                fontWeight: "bold",
                colors: ["#000000"], // Set color of the data label
              },
            },
          },
        },
      },
    },
    stroke: {
      show: false,
      width: 0,
    },
    legend: {
      show: false,
    },
    labels: ["Equipment", "Materials", "Labor"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 500,
          },
          legend: {
            show: false,
            position: "bottom",
          },
        },
      },
    ],
  };

  const headers = [
    "Item name",
    "Item type",
    "Avg unit price",
    "Quantity",
    "Total cost",
  ];
  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 grid grid-cols-12">
        <Tabs
          tabRef={tabRef}
          className={`${
            selectedItem || cardVisible ? "col-span-9" : "col-span-12"
          }`}
          callback={(activeTab) => {
            if (activeTab === 0) {
              setCardVisible(false);
            } else {
              setSelectedItem(null);
              setCardVisible(true);
            }
          }}
        >
          <Tab label="Breakdown">
            <div className="">
              {reportsLoading && !reportsError && !milestonesError ? (
                <ListSkeleton />
              ) : reportsError || milestonesError ? (
                <p className="flex justify-center mt-5 items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {reportsError?.message || milestonesError?.message}
                </p>
              ) : reports?.projects_report?.length ? (
                <Table
                  title="Budget Breakdown"
                  search={true}
                  searchList={tableData}
                  searchPropertiesKey="itemName"
                  searchPlaceholder="Search an item"
                  checkboxClickHandler={selectItem}
                  headers={headers}
                  rows={tableData}
                  errorMessage={
                    reportsError && (
                      <p className="flex justify-center mt-5 items-center">
                        <Warning className="!w-4 !h-4 mr-1" />
                        {reportsError?.message}
                      </p>
                    )
                  }
                />
              ) : (
                <div className="w-full h-full space-y-3 mt-4 flex flex-col items-center justify-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    src={budget}
                    alt="Material details illustration"
                  />
                  <p className="text-gray-700 text-2xl mt-5">
                    No budget reports found
                  </p>
                  <p className="text-dark-gray text-sm">
                    Budget reports will show up here once submitted
                  </p>
                </div>
              )}
            </div>
          </Tab>
          <Tab label="Usage Chart">
            <div className="bg-white p-5">
              <h1 className="font-medium">Budget Breakdown Chart</h1>
              <div className="flex justify-center space-x-8 mt-8">
                <div className="flex flex-col px-5">
                  <span className="text-4xl text-center font-medium">
                    {(
                      chart2.materials +
                      chart2.equipments +
                      chart2.labors
                    ).toLocaleString()}
                  </span>
                  <span className="mt-1">
                    Total Budget
                    <span className="text-gray-500 text-sm ml-1">ETB</span>
                  </span>
                </div>
                <span className="w-1 border-r"></span>
                <div className="flex flex-col px-5">
                  <span className="text-4xl text-center font-medium">
                    {(
                      chart2.materials +
                      chart2.equipments +
                      chart2.labors -
                      (chart1.materials + chart1.equipments + chart1.labors)
                    ).toLocaleString()}
                  </span>
                  <span className="mt-1">
                    Remaining Budget
                    <span className="text-gray-500 text-sm ml-1">ETB</span>
                  </span>
                </div>
                <span className="w-1 border-r"></span>
                <div className="flex flex-col px-5">
                  <span className="text-4xl text-center font-medium">
                    {(
                      chart1.materials +
                      chart1.equipments +
                      chart1.labors
                    ).toLocaleString()}
                  </span>
                  <span className="mt-1">
                    Used Budget
                    <span className="text-gray-500 text-sm ml-1">ETB</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white mt-5 p-5">
              <h1 className="font-medium">Reality</h1>
              <div className="w-full flex justify-around mt-8 h-[200px]">
                {chart1 && (
                  <ApexChart
                    options={chartOptions}
                    series={[
                      chart1.materials,
                      chart1.equipments,
                      chart1.labors,
                    ]}
                    type="pie"
                    width={250}
                    height={250}
                  />
                )}
                <table className="overflow-auto ml-10 horizontal-scrollbar block text-xs text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                      <th></th>
                      <th scope="col" className="px-6 py-3 whitespace-nowrap">
                        Item
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Budget<span className="text-dark-gray"> (ETB)</span>
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td>
                        <p className="w-6 !h-4 bg-chart-blue ml-1"></p>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        Materials
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.materials.toLocaleString()}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.materials + chart1.equipments + chart1.labors >
                        0
                          ? (
                              (chart1.materials /
                                (chart1.materials +
                                  chart1.equipments +
                                  chart1.labors)) *
                              100
                            ).toFixed(2)
                          : 0}
                        %
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td>
                        <p className="w-6 !h-4 bg-chart-yellow ml-1"></p>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        Human Labor
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.labors.toLocaleString()}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.materials + chart1.equipments + chart1.labors >
                        0
                          ? (
                              (chart1.labors /
                                (chart1.materials +
                                  chart1.equipments +
                                  chart1.labors)) *
                              100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                      <td>
                        <p className="w-6 !h-4 bg-chart-red ml-1"></p>
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        Equipment
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.equipments.toLocaleString()}
                      </td>
                      <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                        {chart1.materials + chart1.equipments + chart1.labors >
                        0
                          ? (
                              (chart1.equipments /
                                (chart1.materials +
                                  chart1.equipments +
                                  chart1.labors)) *
                              100
                            ).toFixed(2)
                          : 0}{" "}
                        %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="mt-10">
                <h1 className="font-medium">Initial Expectation</h1>
                <div className="w-full flex justify-around mt-8 h-[200px]">
                  {chart2 && (
                    <ApexChart
                      options={chartOptions}
                      series={[
                        chart2.materials,
                        chart2.equipments,
                        chart2.labors,
                      ]}
                      type="pie"
                      width={250}
                      height={250}
                    />
                  )}
                  <table className="overflow-auto ml-10 horizontal-scrollbar block text-xs text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                      <tr>
                        <th></th>
                        <th scope="col" className="px-6 py-3 whitespace-nowrap">
                          Item
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Budget<span className="text-dark-gray"> (ETB)</span>
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td>
                          <p className="w-6 !h-4 bg-chart-blue ml-1"></p>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          Materials
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.materials.toLocaleString()}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.materials +
                            chart2.equipments +
                            chart2.labors >
                          0
                            ? (
                                (chart2.materials /
                                  (chart2.materials +
                                    chart2.equipments +
                                    chart2.labors)) *
                                100
                              ).toFixed(2)
                            : 0}{" "}
                          %
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td>
                          <p className="w-6 !h-4 bg-chart-yellow ml-1"></p>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          Human Labor
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.labors.toLocaleString()}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.materials +
                            chart2.equipments +
                            chart2.labors >
                          0
                            ? (
                                (chart2.labors /
                                  (chart2.materials +
                                    chart2.equipments +
                                    chart2.labors)) *
                                100
                              ).toFixed(2)
                            : 0}{" "}
                          %
                        </td>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <td>
                          <p className="w-6 !h-4 bg-chart-red ml-1"></p>
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          Equipment
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.equipments.toLocaleString()}
                        </td>
                        <td className="px-6 py-2 whitespace-nowrap dark:text-white">
                          {chart2.materials +
                            chart2.equipments +
                            chart2.labors >
                          0
                            ? (
                                (chart2.equipments /
                                  (chart2.materials +
                                    chart2.equipments +
                                    chart2.labors)) *
                                100
                              ).toFixed(2)
                            : 0}{" "}
                          %
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
        {!cardVisible && reports?.projects_report?.length > 0 && (
          <Card
            // cardRef={cardRef}
            className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
              selectedItem ? "translate-x-0 col-span-3" : "translate-x-full "
            }`}
          >
            {selectedItem ? (
              <Fragment>
                <h1 className="text-start w-full text-lg font-medium">
                  Item details
                </h1>
                <ul className="w-full text-xs space-y-3 p-2">
                  <li className="flex flex-col justify-between">
                    <h3 className="text-gray-700 text-base font-semibold">
                      {selectedItem.itemName}
                    </h3>
                  </li>
                  <hr />
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Item type</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.itemType}
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Average unit price</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.averagePrice} ETB
                      </span>
                    </span>
                  </li>
                  <hr />
                  <h4 className="text-gray-700 text-sm font-medium">
                    Already used
                  </h4>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">
                      {selectedItem.itemType === "Labor"
                        ? "Number of workers"
                        : "Used amount"}
                    </span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.quantity}
                      </span>
                      {selectedItem.unit}
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Total spent</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.totalCost}
                      </span>
                      ETB
                    </span>
                  </li>
                  <h4 className="text-gray-700 text-sm font-medium">
                    Remaining
                  </h4>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Quantity remaining</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.remainingQuantity}
                      </span>
                      {selectedItem.unit}
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Total cost remaining</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedItem.costRemaining}
                      </span>
                      ETB
                    </span>
                  </li>
                </ul>
              </Fragment>
            ) : (
              <Fragment>
                {/* <img src={budget} alt="Material details illustration" />
              <p className="text-dark-gray text-center text-sm">
                Material details will be shown here once selected
              </p> */}
              </Fragment>
            )}
          </Card>
        )}
        <Card
          cardRef={cardRef}
          className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
            cardVisible
              ? "translate-x-0 col-span-3"
              : "translate-x-full !bg-transparent"
          }`}
        >
          {cardVisible && (
            <Fragment>
              <h1 className="text-start w-full text-lg font-medium">
                Additional Budget (ETB)
              </h1>
              <ul className="w-full text-xs space-y-3 p-2">
                <li className="flex flex-col justify-between">
                  <h3 className="text-gray-700 text-base font-semibold">
                    Items
                  </h3>
                </li>
                <hr />
                <li className="flex justify-between">
                  <span className="text-dark-gray">Material</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.materials - chart1.materials).toLocaleString()}{" "}
                      ETB
                    </span>
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray">Human labor</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.labors - chart1.labors).toLocaleString()} ETB
                    </span>
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-dark-gray">Equipment</span>
                  <span>
                    <span className="text-gray-700 font-semibold mr-1">
                      {(chart2.equipments - chart1.equipments).toLocaleString()}{" "}
                      ETB
                    </span>
                  </span>
                </li>
              </ul>
            </Fragment>
          )}
        </Card>
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default BudgetPage;
